/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import { Helmet } from 'react-helmet';

const SEO = (props) => {
  const title = props.seoData.title ? props.seoData.title : ' ';
  const ogtitle = props.seoData.ogtitle ? props.seoData.ogtitle : ' ';
  const description = props.seoData.description ? props.seoData.description : ' ';
  const ogdescription = props.seoData.ogdescription ? props.seoData.ogdescription : ' ';
  const lang = props.seoData.lang ? props.seoData.lang : ' ';
  const image = props.seoData.image ? props.seoData.image.file.url : ' ';
  const author = props.seoData.author ? props.seoData.author : ' ';
  const keywords = props.seoData.keywords ? props.seoData.keywords : ' ';
  const robots = props.seoData.robots ? props.seoData.robots : ' ';
  const revisit_after = props.seoData.revisit_after ? props.seoData.revisit_after : ' ';
  const url = props.url ? props.url : ' ';

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      meta={[
        {
          name: `description`,
          content: description,
        },
        {
          name: `keywords`,
          content: keywords,
        },
        {
          name: `robots`,
          content: robots,
        },
        {
          name: `revisit-after`,
          content: revisit_after,
        },
        {
          name: `author`,
          content: author,
        },
        {
          property: `og:title`,
          content: ogtitle,
        },
        {
          property: `og:description`,
          content: ogdescription,
        },
        {
          property: `og:url`,
          content: url === '/' ? 'https://gaius.dev/' : 'https://gaius.dev/' + url,
        },
        {
          property: `og:image`,
          content: image && 'https:' + image,
        },
        {
          property: `og:image:url`,
          name: `image`,
          content: image && 'https:' + image,
        },
        {
          property: `og:locale`,
          content: 'de',
        },
        {
          property: `og:locale:alternate`,
          content: 'en',
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:creator`,
          content: author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: description,
        },
        {
          name: `twitter:url`,
          content: url,
        },
        {
          name: `twitter:image`,
          content: image && 'https:' + image,
        },
      ]}
    >
      {url && <link rel="canonical" href={url} />}
    </Helmet>
  );
};

export default SEO;
